import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { merge, unset } from 'lodash'

interface NotificationFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;
  cities: any[];
  brands: any[];
  outlets: any[];
  promotions: any[];
  merchant_categories: any[];
  notification_count: number;
}

const $state: NotificationFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,
  cities: [],
  brands: [],
  outlets: [],
  promotions: [],
  merchant_categories: [],
  notification_count: 0,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  cities: [],
  brand: null,
  outlet: null,
  category: null,
  subcategory: null,
  age_from: 18,
  age_to: 100,
  sex: 'm',
  message: '',
  title: '',
  send_datetime: null,
  include: '',
  exclude: '',
  receipt_datetime_from: null,
  receipt_datetime_to: null,
  receipt_sum_from: 0,
  receipt_sum_to: 0,
}

const AVAILABLE_FIELDS = [
  'brand', 'outlet', 'age_from', 'age_to', 'sex', 'message', 'cities', 'send_datetime', 'category', 'subcategory',
  'include', 'exclude', 'receipt_datetime_from', 'receipt_datetime_to', 'receipt_sum_from', 'receipt_sum_to', 'title',
]

export const $actions: ActionTree<NotificationFormState, RootState> = {

  loadData({ commit, state, dispatch }, params) {
    const vm = (this as any)._vm
    commit('START_LOADING')
    dispatch('preloadData').then(() => {
      if (params.id > 0) {
        Promise.all([
          vm.$http.get('/admins/rest/v1/notifications/' + params.id),
        ])
          .then(([notificationResp]) => {
            commit('SET_FORM_DATA', notificationResp.data)
            commit('LOADED_SUCCESS')
            dispatch('updateNotificationCount')
          })
      }
      else {
        new Promise((resolve, reject) => {
          commit('GENERATE_FORM_DATA', {})
          commit('LOADED_SUCCESS')
        })
      }
    })
  },

  preloadData({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          resolve(true)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
      vm.$http
        .get('/admins/rest/v1/brands', { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_BRANDS', response.data)
          commit('LOADED_SUCCESS')

          resolve(true)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
      vm.$http
        .get('/admins/rest/v1/outlets', { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_OUTLETS', response.data)
          commit('LOADED_SUCCESS')

          resolve(true)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
      vm.$http
        .get('/admins/rest/v1/merchant_categories', { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CATEGORIES', response.data)
          commit('LOADED_SUCCESS')

          resolve(true)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
      vm.$http
        .get('/admins/rest/v1/promotion', { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_PROMOTIONS', response.data)
          commit('LOADED_SUCCESS')

          resolve(true)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  changeFormValue({ commit, dispatch }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
    dispatch('updateNotificationCount')
  },

  updateNotificationCount({ commit, state }) {
    const vm = (this as any)._vm
    const params: any = {
      'brand_id': state.form.brand ? state.form.brand.id : null,
      'outlet_id': state.form.outlet ? state.form.outlet.id : null,
      'age_from': state.form.age_from,
      'age_to': state.form.age_to,
      'sex': state.form.sex,
      'category_id': state.form.category ? state.form.category.id : null,
      'subcategory_id': state.form.subcategory ? state.form.subcategory.id : null,
      'include': state.form.include,
      'exclude': state.form.exclude,
      'receipt_datetime_from': state.form.receipt_datetime_from ? moment(state.form.receipt_datetime_from).format('YYYY-MM-DD') : null,
      'receipt_datetime_to': state.form.receipt_datetime_to ? moment(state.form.receipt_datetime_to).format('YYYY-MM-DD') : null,
      'receipt_sum_from': state.form.receipt_sum_from,
      'receipt_sum_to': state.form.receipt_sum_to,
      'cities': state.form.cities.map((item: any) => {
        return item.id
      }),
    }
    vm.$http
      .get(`/admins/rest/v1/notifications/count`, { params })
      .then((response: AxiosResponse) => {
        commit('SET_NOTIFICATION_COUNT', response.data)
      })

  },

  saveObject({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      if (!state.form.contacts || !state.form.contacts.contacts) {
        state.form.contacts = { contacts: [] }
      }

      const cleanForm: any = {}
      for (const key of AVAILABLE_FIELDS) {
        if (state.form[key]) {
          cleanForm[key] = state.form[key]
        }
      }
      cleanForm.send_datetime = moment(cleanForm.send_datetime).format('YYYY-MM-DD HH:mm:ss')

      commit('START_LOADING')

      let request = null

      if (id) {
        request = vm.$http.patch(`/admins/rest/v1/notifications/${id}`, {
          notification: cleanForm,
        })

      }
      else {
        request = vm.$http.post(`/admins/rest/v1/notifications`, {
          notification: cleanForm,
        })
      }

      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })
  },
}

export const $mutations: MutationTree<NotificationFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  SET_NOTIFICATION_COUNT: (state, { notification_count }) => {
    state.notification_count = notification_count
  },
  ADD_CONTACT: (state, { contact, type }) => {
    console.log(state.form)
    if (!state.form.contacts || !state.form.contacts.contacts) {
      state.form.contacts = { contacts: [] }
    }
    state.form.contacts.contacts.push({
      value: contact,
      type,
    })
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
  SET_BRANDS: (state, { brands }) => {
    state.brands = brands
  },
  SET_OUTLETS: (state, { outlets }) => {
    state.outlets = outlets
  },
  SET_CATEGORIES: (state, { merchant_categories }) => {
    state.merchant_categories = merchant_categories
  },
  SET_PROMOTIONS: (state, { promotions }) => {
    state.promotions = promotions
  },

  SET_FORM_DATA(state, { notification }) {
    notification.send_datetime = moment(notification.send_datetime).toDate()
    state.object = notification

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },

  CHANGE_VALUE(state: NotificationFormState, { field, newValue }) {
    state.form[field] = newValue
  },

  ON_FORM_SAVE(state, { object }) {
    state.object = object

    const formData = cloneDeep(object)

    state.form = {
      ...formData,
    }
  },

}

const $getters = {
  outlets: (state: any) => {
    return state.outlets.filter((item: any) => {
      return state.form.brand && item.brand_id === state.form.brand.id
    })
  },
  merchant_subcategories: (state: any) => {
    return state.merchant_categories.filter((item: any) => {
      return !state.form || !state.form.category || state.form.category.id === item.parent_id
    })
  },
}

export const STORE_KEY = '$_notification_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
