






























































































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
// import CompanyDetails from '../../Customers/СompanyDetails/СompanyDetails.vue';

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty', 'cities', 'brands', 'notification_count',
      'merchant_categories', 'promotions',
    ]),
    ...mapGetters(STORE_KEY, [
      'outlets', 'merchant_subcategories',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      cities: {},
      brand: {},
      outlet: {},
      promotion: {},
      age_from: { required },
      age_to: { required },
      sex: {},
      title: {},
      message: { required },
      send_datetime: { required },
      category: {},
      subcategory: {},
      include: {},
      exclude: {},
      receipt_datetime_from: {},
      receipt_datetime_to: {},
      receipt_sum_from: {},
      receipt_sum_to: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
  },
})
export default class Notifications extends Vue {

  public FILES_HOST = process.env.VUE_APP_UBIC_SERVER_ADDRESS
  public newContact: string = ''
  public newContactType: string = 'phone'

  public sexs: any[] = [
    { text: 'мужской', value: 'm' },
    { text: 'женский', value: 'f' },
    { text: 'не важно', value: null },
  ]

  get isNew() {
    return !!this.$route.params.id
  }

  get titleStack() {
    return [
      // @ts-ignore
      this.$route.params.id > 0 ? 'Редактировать рассылку' : 'Новая рассылка',
    ]
  }

  public addContact() {
    this.$store.dispatch(`${STORE_KEY}/addContact`, {
      contact: this.newContact, type: this.newContactType,
    })
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.$route.params.id)
        .then((result) => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)

          this.$router.push({ name: 'notifications' })
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: `При сохраненит возникли проблемы. Попробуйте позже.`,
          })
        })
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.params)
    // this.$store.dispatch(`${STORE_KEY}/preloadData`);
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
